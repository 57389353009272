import React from 'react'
import GoogleMapReact from 'google-map-react'

const defaultProps = {
    center: {
        lat: 48.14424196251511,
        lng: 17.10763009540163,
    },
    zoom: 15,
}

const AnyReactComponent = ({ text }) => <div>{text}</div>

const GoogleMap = () => (
    <div style={{ height: '50vh', width: '100%' }}>
        <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyDRAGWWH2vwUmaJLOzouDzR-xb0nwffoV4'}}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
        >
            <AnyReactComponent
                lat={48.14424196251511}
                lng={17.10763009540163}
                text={'JAZZTIKOT'}
            />
        </GoogleMapReact>
    </div>
)

export default GoogleMap
