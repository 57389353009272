import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMapMarker, faPhone, faClock} from "@fortawesome/free-solid-svg-icons";
import {Link} from "gatsby";
import Nadpis from "../Layout/Nadpis";
import GoogleMap from "../Components/GoogleMap";
import {faFacebook} from "@fortawesome/free-brands-svg-icons";
import {StaticImage} from "gatsby-plugin-image";

const Kontakt = () => (
    <section className={"kontakt"}>

        <div className="container">

            <h2 id={"kontakt"} className={"text-center mb-4"}>Kde nás nájdete
                <span>Kontakt</span>
            </h2>

            <div className="row">
                <div className="col-12 col-md">
                    <div className="bigIco">
                        <FontAwesomeIcon icon={faMapMarker}/>
                        <div className={"icoText"}>Biela 5, Bratislava</div>
                    </div>

                </div>
                <div className="col-12 col-md">
                    <div className="bigIco">
                        <FontAwesomeIcon icon={faClock}/>
                        <div className={"icoText"}>Ut - So <br/>
                        10:00 - 00:00
                        </div>
                    </div>

                </div>
                <div className="col-12 col-md">
                    <div className="bigIco">
                        <FontAwesomeIcon icon={faPhone}/>
                        <div className={"icoText"}>
                            +421 948 327 333 <br/>
                            +421 915 178 895</div>
                    </div>

                </div>
            </div>

            <GoogleMap/>

            <div className="row mt-4">
                <div className="col"></div>
                <div className="col-12 col-sm text-center">

                    <StaticImage imgClassName={"imgHoverEffect"} src="../../assets/custom-style/images/partners/jazz.jpg" alt="Jazz.sk"/>

                </div>
                <div className="col-12 col-sm text-center">
                    <StaticImage imgClassName={"imgHoverEffect"} src="../../assets/custom-style/images/partners/skjazz.jpg" alt="Jazz.sk"/>
                </div>
                <div className="col-12 col-sm text-center">
                    <StaticImage imgClassName={"imgHoverEffect"} src="../../assets/custom-style/images/partners/mojakultura.jpg" alt="Jazz.sk"/>
                </div>
                <div className="col"></div>
            </div>
        </div>

    </section>

);

export default Kontakt

