import React from "react";

import CanvaLogo from '../../assets/custom-style/images/canva-logo.png';

const Onas = () => (
    <section className={"news bg-light-border"}>

        <div className="container">

                <div className="row">
                        <div className="col-md-3">
                                <img className={"w-100"} src={CanvaLogo}/>
                        </div>

                        <div className="col-md-9">
                                <div className="card-body">
                                        <h5 className="card-title text-uppercase fw-bold text-secondary">Navštívte nás</h5>
                                        <p className="card-text">Príďte k nám ochutnať silné caffé <strong>
                                                Italiano Trieste
                                        </strong> s maslovým croissantom, osviežiť sa českým pivom <strong>Svijany</strong> alebo limonádou, vychutnať si výborné francúzske víno a syry.</p>
                                        <p className="card-text">
                                                Nechýba kvalitná whisky ani whiskey, francúzsky cognac, calvados a alkoholu všehochuť!

                                        </p>
                                        <p className="card-text">
                                        Čoskoro bude v ponuke aj niečo dobré na zahryznutie :)
                                        </p>
                                </div>


                                <p className="lead">

                                </p>
                                <p className="lead">

                                </p>
                        </div>
                </div>




        </div>
    </section>

);

export default Onas

